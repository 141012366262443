@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --top-space: calc(3rem + 95px);
}

html {
  font-family: "Archivo", sans-serif;
}
